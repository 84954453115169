<!--
 * @Author: DY
 * @Date: 2020-08-13 15:50:02
 * @LastEditTime: 2021-03-01 15:19:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Mobile_test\src\views\control\orderControlDetails.vue
-->
<template>
    <div class="orderControlDetails">
        <table>
            <tbody>
                <tr>
                    <th style="width: 1.3rem;">
                        授信方量
                    </th>
                    <td colspan="6" style="width: 3.6rem;">
                        <strong>授信方量：</strong>
                        <span>{{ controlDetails.credit_quantity || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>耗用授信方量：</strong>
                        <span>{{ controlDetails.credit_consume_quantity || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>剩余授信方量：</strong>
                        <span>{{ controlDetails.credit_surplus_quantity || 0 }}</span>
                    </td>
                </tr>
                <tr>
                    <th style="width: 1.3rem;">
                        授信金额
                    </th>
                    <td colspan="6" style="width: 3.6rem;">
                        <strong>授信金额：</strong>
                        <span>{{ controlDetails.credit_money || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>耗用授信金额：</strong>
                        <span>{{ controlDetails.credit_consume_money || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>剩余授信金额：</strong>
                        <span>{{ controlDetails.credit_surplus_money || 0 }}</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        订单总金额
                        <el-button
                            size="mini"
                            type="primary"
                            style="margin-top: 0.1rem;"
                            @click="openTableList"
                            v-if="isBtn"
                        >
                            查看明细
                        </el-button>
                    </th>
                    <td
                        colspan="2"
                        style="width: 1.2rem; cursor: pointer;"
                        class="textAlign"
                    >
                        <h5>订单数(个)</h5>
                        <span>{{ controlDetails.order_count || 0 }}</span>
                    </td>
                    <td colspan="2" style="width: 1.2rem;" class="textAlign">
                        <h5>订单方量(方)</h5>
                        <span>{{ controlDetails.order_total_quantity || 0 }}</span>
                    </td>
                    <td colspan="2" style="width: 1.2rem;" class="textAlign">
                        <h5>订单总金额(元)</h5>
                        <span>{{ controlDetails.order_total_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        订单创建时间为补录截止日之后审核通过的订单总金额（如果某个订单的发货总金额大于任务总金额，则订单总金额取该订单的发货总金额）
                    </td>
                </tr>
                <tr>
                    <th rowspan="7">
                        发货总金额
                    </th>
                    <td colspan="2" class="textAlign">
                        <h5>发货单数(个)</h5>
                        <span>{{ controlDetails.delivery_count || 0 }}</span>
                    </td>
                    <td colspan="2" class="textAlign">
                        <h5>发货总方量(方)</h5>
                        <span>{{ controlDetails.delivery_total_quantity || 0 }}</span>
                    </td>
                    <td colspan="2" class="textAlign">
                        <h5>发货总金额(元)</h5>
                        <span>{{ controlDetails.delivery_total_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        补录截止日之后系统中发货计算的金额(上线场站)<i class="icon1">+</i>补录截止日之后按日补录发货金额(非上线场站)
                    </td>
                </tr>
                <tr>
                    <td rowspan="6" colspan="1" style="width: 0.6rem; padding:0; text-align: center">
                        其中
                    </td>
                    <td colspan="5" style="width: 3rem;" class="textAlign">
                        <strong>已结算：</strong>
                        <span>{{ controlDetails.delivery_settlement_amount || 0 }}</span>
                    </td>
                    <td colspan="10">
                        按照结算周期，完成合同结算处理的发货金额
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="width: 3rem;" class="textAlign">
                        <strong>已对账：</strong>
                        <span>{{ controlDetails.delivery_statement_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        按照对账周期，完成合同对账并与工地确认后的发货金额
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="width: 3rem;" class="textAlign">
                        <strong>已核对核算：</strong>
                        <span>{{ controlDetails.delivery_check_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        未到结算期，按站统计核对调整后的方量，结算中心已核算的发货金额
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="textAlign">
                        <strong>已办结(签收)，未核对：</strong>
                        <span>{{ controlDetails.delivery_sign_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        司机送达/办结后，按客户录入签收方量，系统自动计算的发货金额
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="textAlign">
                        <strong>已打料完成未办结(签收)：</strong>
                        <span>{{ controlDetails.delivery_product_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        已打料完成，按发货单上打印的方量，系统自动计算的发货金额
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="textAlign">
                        <strong>已派单未打料完成：</strong>
                        <span>{{ controlDetails.delivery_dispatch_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        按调度派单且司机已接单的派车单的发货方量，系统自动计算的发货金额
                    </td>
                </tr>
                <tr>
                    <th rowspan="1">
                        回款金额
                    </th>
                    <td colspan="3" class="textAlign">
                        <h5>笔数(次)</h5>
                        <span>{{ controlDetails.cashed_total_count || 0 }}</span>
                    </td>
                    <td colspan="3" class="textAlign">
                        <h5>金额(元)</h5>
                        <span>{{ controlDetails.cashed_total_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        补录截止日之后的收款记录
                        <!-- <i class="icon2">-</i>补录数据最后一期的应收余额 -->
                        <i class="icon2">-</i>补录截止日之后的退款记录
                    </td>
                </tr>
                <!-- <tr>
                    <td colspan="1" style="padding:0; text-align: center">
                        其中
                    </td>
                    <td colspan="5">
                        <strong>收款：</strong>
                        <span>{{ controlDetails.cashed_amount || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>补录期末应收余额：</strong>
                        <span>{{ controlDetails.zkbl_accounts_receivable_balance || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>退款：</strong>
                        <span>{{ controlDetails.refund_receipt || 0 }}</span>
                    </td>
                </tr> -->
                <tr>
                    <th rowspan="2">
                        合同欠款
                    </th>
                    <td colspan="6">
                        <strong>金额(元):</strong>
                        <span>{{ controlDetails.cumulative_balance || 0 }}</span>
                    </td>
                    <td colspan="10">
                        合同欠款<i class="icon3">=</i>订单总金额<i class="icon2">-</i>回款金额<i class="icon1">+</i>补录数据最后一期的应收余额
                    </td>
                </tr>
                <tr>
                    <td colspan="1" style="padding:0; text-align: center">
                        其中
                    </td>
                    <td colspan="5">
                        <strong>订单总金额：</strong>
                        <span>{{ controlDetails.order_total_money || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>回款金额：</strong>
                        <span>{{ controlDetails.cashed_total_money || 0 }}</span>
                    </td>
                    <td colspan="5">
                        <strong>补录期末应收余额：</strong>
                        <span>{{ controlDetails.zkbl_accounts_receivable_balance || 0 }}</span>
                    </td>
                </tr>
                <tr v-if="controlDetails.business_type == 1">
                    <th>当前订单金额</th>
                    <td colspan="6">
                        <strong>金额(元):</strong>
                        <span>{{ controlDetails.apply_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        按拟新建订单中的订单方量，系统自动计算的发货金额
                    </td>
                </tr>
                <tr v-if="controlDetails.business_type == 2">
                    <th>当前发货单金额</th>
                    <td colspan="6">
                        <strong>金额(元):</strong>
                        <span>{{ controlDetails.apply_money || 0 }}</span>
                    </td>
                    <td colspan="10">
                        按新建发货单的发货方量，系统自动计算的发货金额
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 订单管控详情
    name: 'order-control-details',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 管控详情数据
            controlDetails: {},
            isBtn: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.extr && this.extr.type && this.extr.type === 2) {
            this.isBtn = true;
        }
    },
    mounted() {
        // 拉取管控详情
        this.getControlList();
    },
    destroyed() {},
    methods: {
        // 拉取管控详情
        getControlList() {
            this.$axios
                .get(`/interfaceApi/sale/control/data/${this.extr.control_instance_code}/${this.extr.type}`)
                .then(res => {
                    if (res) {
                        this.controlDetails = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        openTableList() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '15.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'order_control_data_list',
                        QueryString: `contract_number=${this.extr.control_instance_code}`,
                    },
                },
            });
        },
    },
};
</script>
<style lang="stylus" scoped>
.orderControlDetails
    width 100%
    padding 0.15rem
    table
        width 100%
        border-collapse collapse
        table-layout fixed
        border 1px solid #666666
        tr
            background #E6EEFF
            th
                background #B4C6E7
            &:nth-of-type(1),
            &:nth-of-type(2)
                background #ECF5FF
                th,td
                    border-bottom 1px solid #666666
                th
                    background #BDD6EE
            &:nth-of-type(3)
                background #DCF2F9
                th,td
                    border-bottom 1px solid #666666
                th
                    background #B2E4F5
            &:nth-of-type(4)
                background #DEF0D8
                th
                    border-bottom 1px solid #666666
                th
                    background #9CD286
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8),
            &:nth-of-type(9),
            &:nth-of-type(10)
                background #FAFFF9
                td
                    border 1px solid #D5EBE5
                    border-right none
            &:nth-of-type(5)
                td
                    &:first-child
                        border-bottom 1px solid #666666
            &:nth-of-type(10)
                th,td
                    border-bottom 1px solid #666666
            &:nth-of-type(11)
                background #FFE8CD
                th
                    background #FFC9A8
                    border-bottom 1px solid #666666
            &:nth-of-type(12)
                background #FEF5E9
                th,td
                    border-bottom 1px solid #666666
            &:nth-of-type(13)
                background #FFEEEE
                th,td
                    border-bottom 1px solid #666666
                th
                    background #FFBDBD
            &:nth-of-type(14)
                th,td
                    border-bottom 1px solid #666666
        th
            font-size 0.16rem
            color #000000
            font-weight 400
        td
            height 0.46rem
            font-size 0.16rem
            position relative
            padding 0.05rem 0.2rem
            &:after
                position absolute
                left 0
                top 0
                content ''
                background #fff
                width 2px
                height 100%
            &.textAlign
                text-align right
            strong
                display inline-block
                height 0.26rem
                font-weight 400
                line-height 0.26rem
                vertical-align top
            span
                display inline-block
                height 0.26rem
                font-size 0.2rem
                line-height 0.26rem
                color #0163AD
                vertical-align top
                margin-left 0.05rem
            i
                display inline-block
                width 0.2rem
                height 0.2rem
                font-size 0.24rem
                line-height 0.14rem
                text-align center
                color #fff
                margin 0 0.05rem
                vertical-align middle
                margin-top -2px
                &.icon1
                    border-radius 2px
                    background-image: linear-gradient(#9CD286, #54A035);
                    border 1px solid #4F8933
                &.icon2
                    border-radius 2px
                    background-image: linear-gradient(#FFAF7D, #EE7A33);
                    border 1px solid #E6732C
                &.icon3
                    border-radius 50%
                    background-image: linear-gradient(#3A96FE, #40B6FF);
                    border 1px solid #2EA1E8
</style>
